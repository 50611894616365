import { navigate } from "vike/client/router";
import { Slide } from "react-awesome-reveal";
import { useEffect, useRef, useState } from "react";

import Button from "@/components/ButtonLink";
import { Link } from "@/components/Link";
import Logo from "@/components/Logo";

export default function Home() {
  const pageBottomRef = useRef<HTMLDivElement>(null);
  const [transparentHeader, setTransparentHeader] = useState(false);

  // Function to calculate the gradient background color as the page is scrolled
  useEffect(() => {
    const pageWrapper = document.getElementById("page-wrapper");

    const calculateGradientBg = () => {
      const root = document.documentElement;

      const y = pageWrapper!.scrollTop / (pageWrapper!.scrollHeight - pageWrapper!.clientHeight);

      const colorStart = mixColors("#D2E9FF", "#775BD4", y);
      const colorEnd = mixColors("#D2E9FF", "#775BD4", y);

      setTransparentHeader(y < 0.0001 ? true : false);

      root.style.setProperty("--gradient-start", colorStart);
      root.style.setProperty("--gradient-end", colorEnd);
    };

    calculateGradientBg();

    pageWrapper!.addEventListener("scroll", calculateGradientBg);
  }, []);

  return (
    <div
      className="gradient-bg-landingpage relative z-10 flex h-screen max-w-[100vw] snap-y snap-mandatory flex-col items-center justify-start overflow-x-hidden overflow-y-scroll scroll-smooth antialiased"
      id="page-wrapper"
    >
      <header className="fixed top-0 z-[1000] flex w-full max-w-screen-xl flex-row">
        <nav
          className={`flex w-full max-w-7xl items-center justify-between p-6 mt-0 md:m-8 transition-colors rounded-none md:rounded-2xl lg:px-8 ${transparentHeader ? "lg:bg-transparent" : "bg-white shadow-md"}`}
          aria-label="Global"
        >
          <div className="flex lg:flex-1 transition-opacity">
            <a href="/" className="-m-1.5 p-1.5 text-primary">
              <Logo />
            </a>
          </div>
          <div className="flex justify-end transition-opacity">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <button className="btn btn-primary btn-outline btn-sm sm:btn-md" onClick={() => navigate("/login")}>
                Sign in
              </button>
              <button className="btn btn-primary btn-sm hidden sm:block sm:btn-md" onClick={() => navigate("/signup")}>
                Sign up
              </button>
            </div>
          </div>
        </nav>
      </header>

      {/* Hero section */}
      <div className="relative flex max-h-screen min-h-screen snap-center snap-always flex-col items-center justify-center pt-20">
        <img src="/landing-page/hero-cloud.svg" className="" />
        <img src="/landing-page/hero.gif" className="z-10 -mt-20 w-full max-w-screen-sm px-10 md:w-2/3" />
        <img src="/landing-page/hero-chevron.svg" className="animate-bounce duration-500 ease-out" />
      </div>

      {/* Dream collections section - desktop */}
      <div className="relative hidden h-screen max-h-screen min-h-screen w-full max-w-[830px] snap-center snap-always flex-row gap-8 p-2 pt-20 md:flex">
        <div className="relative z-10 flex w-1/2 flex-col gap-4 self-center" id="dream-collections-text">
          <Slide cascade damping={0.1} duration={2000}>
            <span className="font-bricolage text-[32px] font-bold leading-6 text-white shadow-[#775BD459]/35 drop-shadow-md">
              Dream collections
            </span>
            <span className="text-xl text-[#4D30AA]">
              Curate your dream collection with items from our DreamVault. Invite friends, family, and supporters.
            </span>

            <div className="w-fit">
              <Button className="w-auto" label="Start dreaming" onClick={() => navigate("/login")} />
            </div>

            <img src="/landing-page/cloud.svg" className="pointer-events-none absolute -top-24 left-1/2" />
          </Slide>
        </div>
        <div className="relative z-10 flex w-2/3 flex-col items-center justify-center gap-4 self-center md:w-1/2">
          <img
            src="/landing-page/dream-collections-building.png"
            className="pointer-events-none -mb-24 mr-auto"
            id="dream-collections-building"
          />

          <Slide
            direction="down"
            cascade
            duration={2000}
            className="absolute left-10 z-10 flex aspect-square w-[145px] flex-col gap-3 "
          >
            <img
              alt=""
              className="h-full w-full object-cover object-center"
              src="/landing-page/dream-collections-card-2.png"
            />
          </Slide>
          <Slide
            direction="down"
            cascade
            duration={2000}
            className="absolute -left-4 mb-24 flex aspect-square w-[113px] flex-col gap-3"
          >
            <img
              alt=""
              className="h-full w-full object-cover object-center"
              src="/landing-page/dream-collections-card-3.png"
            />
          </Slide>
          <Slide
            direction="down"
            cascade
            duration={2000}
            className="absolute right-44 mb-24 flex aspect-square w-[113px] flex-col gap-3"
          >
            <img
              alt=""
              className="h-full w-full object-cover object-center"
              src="/landing-page/dream-collections-card-1.png"
            />
            <Slide />
          </Slide>
          <Slide
            duration={2000}
            direction="right"
            className="pointer-events-none absolute right-0 my-auto w-full max-w-[272px]"
          >
            <img id="dream-collections-girl" src="/images/gifs/girl-headphone.gif" />
          </Slide>
          <Slide
            duration={2000}
            direction="right"
            className="pointer-events-none absolute -left-2 bottom-2 w-full max-w-[240px]"
          >
            <img src="/landing-page/cloud.svg" />
          </Slide>
        </div>
      </div>

      {/* Dream collections section - mobile */}
      <div className="relative flex h-[100svh] max-h-[100svh] min-h-[100svh] w-full max-w-[830px] snap-center snap-always flex-row p-2 pt-20 md:hidden">
        <div className="relative z-10 flex w-2/3 flex-col items-center gap-4 self-center rounded-3xl bg-white p-4">
          <Slide
            direction="down"
            cascade
            duration={2000}
            className="left-10 z-10 flex aspect-square w-[145px] flex-col gap-3 "
          >
            <img
              alt=""
              className="h-full w-full object-cover object-center"
              src="/landing-page/dream-collections-card-2.png"
            />
          </Slide>
          <Slide
            direction="down"
            cascade
            duration={2000}
            className="absolute -top-10 left-4 mb-24 flex aspect-square w-[113px] flex-col  gap-3 "
          >
            <img
              alt=""
              className="h-full w-full object-cover object-center"
              src="/landing-page/dream-collections-card-3.png"
            />
          </Slide>
          <Slide
            direction="down"
            cascade
            duration={2000}
            className="absolute -top-10 right-4 flex aspect-square w-[113px] flex-col  gap-3 "
          >
            <img
              className="h-full w-full object-cover object-center"
              src="/landing-page/dream-collections-card-2.png"
            />
          </Slide>
          <Slide cascade damping={0.1} duration={2000} className="z-[100] flex flex-col items-center">
            <span className="text-center font-bricolage text-[24px] font-bold leading-8 text-[#775BD4]">
              Dream collections
            </span>
            <span className="text-center text-sm text-black">
              Curate your dream collection with items from our DreamVault. Invite friends, family, and supporters to
              become your builders.
            </span>
            <Button className="w-auto" label="Start dreaming" onClick={() => navigate("/login")} />
          </Slide>
        </div>
        <div className="relative z-10 flex w-1/3 flex-col items-center justify-center gap-4 self-center">
          <Slide direction="up" className="pointer-events-none -left-24 mt-auto w-[310px] self-center">
            <img src="/images/gifs/girl-headphone.gif" />
          </Slide>
          <Slide
            duration={2000}
            direction="right"
            className="pointer-events-none absolute -left-2 bottom-2 w-full max-w-[240px]"
          >
            <img src="/landing-page/cloud.svg" />
          </Slide>

          <img
            src="/landing-page/dream-collections-building.png"
            className="pointer-events-none absolute -z-10 -mb-36 mr-auto h-[70vh]"
          />
        </div>
      </div>

      {/* Custom dream links section - desktop */}
      <div className="relative hidden min-h-screen w-full max-w-[830px] snap-center snap-always flex-row items-center gap-4 gap-4 p-2 pt-20 md:flex">
        <div className="relative z-10 flex w-1/2 flex-col justify-center gap-4 self-center">
          <Slide className="w-1/2" duration={2000} direction="up">
            <img src="/landing-page/custom-dreams-building.svg" className="h-[812px]" />
          </Slide>
          <Slide className="pointer-events-none absolute -left-20 z-10 my-auto hidden w-full md:block" duration={2000}>
            <img src="/images/gifs/boy-scout.gif" className="pointer-events-none w-full max-w-[266px] !-scale-x-100" />
          </Slide>
          <Slide className="absolute right-8 my-auto w-2/3">
            <div className="ml-auto flex flex-col items-center gap-4 self-center rounded-3xl bg-white p-4 py-8">
              <img src="/landing-page/custom-dreamlinks-input.png" className="z-0" />
            </div>
          </Slide>
        </div>
        <div className="relative z-10 flex w-2/3 flex-col gap-4 self-center md:w-1/2" id="custom-dreamlinks-text">
          <Slide cascade duration={2000} direction="right" damping={0.1}>
            <span className="font-bricolage text-[32px] font-bold leading-6 text-white shadow-[#775BD459]/35 drop-shadow-md">
              Custom DreamLinks
            </span>
            <span className="text-xl text-[#4D30AA]">
              {`Can’t find what you're looking for in our DreamVault? Create a
							custom link from anywhere on the web and let your builders make it
							happen.`}
            </span>
            <div className="w-fit">
              <Button label="Dream It, Unlock It!" onClick={() => navigate("/login")} />
            </div>
          </Slide>
          <Slide
            duration={2000}
            direction="right"
            className="pointer-events-none absolute -left-2 bottom-2 -z-10 w-full max-w-[240px]"
          >
            <img src="/landing-page/cloud.svg" />
          </Slide>
        </div>
      </div>

      {/* Custom dream links section - mobile */}
      <div className="relative flex h-[100svh] max-h-[100svh] min-h-[100svh] w-full max-w-[830px] snap-center snap-always flex-row p-2 pt-20 md:hidden">
        <div className="relative z-10 ml-auto flex w-2/3 flex-col items-center gap-4 self-center rounded-3xl bg-white p-4">
          <Slide direction="right" duration={2000} className="relative w-full">
            <img src="/landing-page/custom-dreamlinks-input.png" className="z-0" />
          </Slide>

          <Slide cascade damping={0.1} duration={2000} direction="right" className="z-10 flex flex-col items-center">
            <span className="mt-6 text-center font-bricolage text-[24px] font-bold leading-8 text-[#775BD4]">
              Custom DreamLinks
            </span>
            <span className="text-center text-sm text-black">
              {`Can’t find what you're looking for in our DreamVault? Create a
							custom link from anywhere on the web and let your builders make it
							happen.`}
            </span>
            <Button className="w-auto" label="Dream It, Unlock It!" onClick={() => navigate("/login")} />
          </Slide>

          <Slide
            duration={2000}
            direction="left"
            className="pointer-events-none absolute -left-20 bottom-2 -z-10 w-full max-w-[240px]"
          >
            <img src="/landing-page/cloud.svg" />
          </Slide>
        </div>
        <div className="absolute left-0 flex w-1/3 flex-col items-center justify-center gap-4 self-center">
          <div className="pointer-events-none w-[296px]">
            <Slide direction="up" className="pointer-events-none absolute top-20 z-[5] w-[296px]">
              <img src="/images/gifs/boy-scout.gif" className="!-scale-x-100" />
            </Slide>
            <img src="/landing-page/custom-dreams-building.svg" className="bottom-0 h-[812px]" />
          </div>
        </div>
      </div>

      {/* Big dreams section - desktop */}
      <div className="relative hidden min-h-screen w-full max-w-[830px] snap-center snap-always flex-row items-center gap-4 p-2 pt-20 md:flex">
        <div className="flex w-2/3 flex-col items-start gap-4 rounded-3xl px-4 py-10 md:w-1/2" id="big-dreams-text">
          <Slide duration={2000} cascade damping={0.1}>
            <span className="text-left font-bricolage text-4xl font-medium leading-6 text-white shadow-[#775BD459]/35 drop-shadow-md">
              Big dreams
            </span>
            <span className="text-left text-xl text-white">
              Need hard cash to fuel your creativity? No problem! Crowdfund, and together, we can unlock your dreams!
            </span>
            <div className="w-fit">
              <Button label="Fund your Dreams" onClick={() => navigate("/login")} />
            </div>
          </Slide>
          <Slide
            duration={2000}
            direction="left"
            className="pointer-events-none absolute -left-20 bottom-10 -z-10 w-full max-w-[240px]"
          >
            <img src="/landing-page/cloud.svg" />
          </Slide>
        </div>
        <div
          className="relative z-10 flex w-1/2 flex-col items-start justify-start gap-4 rounded-3xl px-4 py-10 pt-20"
          id="big-dreams-image"
        >
          <Slide direction="right" duration={2000} cascade damping={0.1}>
            <img src="/landing-page/big-dreams.png" className="pointer-events-none z-10 mr-auto w-full max-w-[187px]" />
          </Slide>
          <Slide
            direction="right"
            className="pointer-events-none absolute -bottom-10 right-0 z-10 w-full max-w-[302px]"
            duration={2000}
            cascade
            damping={0.1}
          >
            <img src="/images/gifs/big-dreams-boy.gif" />
          </Slide>
        </div>
      </div>

      {/* Big dreams section - mobile */}
      <div className="relative flex h-[100svh] max-h-[100svh] min-h-[100svh] w-full max-w-[830px] snap-center snap-always flex-row p-2 pt-20 md:hidden">
        <div className="relative flex w-2/3 flex-col items-center gap-4 self-center rounded-3xl bg-white p-4">
          <Slide className="" duration={2000} cascade damping={0.1}>
            <div className="relative -mb-10 -mt-20 flex flex-col items-start justify-start gap-4 rounded-3xl pb-4">
              <img src="/landing-page/big-dreams.png" className="pointer-events-none mr-auto w-full" />
            </div>
          </Slide>
          <Slide cascade damping={0.1} duration={2000} className="z-10 flex flex-col items-center">
            <span className="z-10 text-center font-bricolage text-[24px] font-bold leading-8 text-[#775BD4]">
              Big dreams
            </span>
            <span className="z-10 text-center text-sm text-black">
              Need hard cash to fuel your creativity? No problem! Crowdfund, and together, we can unlock your dreams!
            </span>
            <Button className="w-auto" label="Fund your Dreams" onClick={() => navigate("/login")} />
          </Slide>
        </div>
        <div className="absolute right-0 z-10 flex w-1/3 flex-col items-center justify-center gap-4 self-center">
          <Slide
            duration={2000}
            direction="right"
            className="pointer-events-none absolute -left-20 top-0 -z-10 w-full max-w-[240px]"
          >
            <img src="/landing-page/cloud.svg" />
          </Slide>
          <img
            src="/landing-page/big-dreams-building.svg"
            className="pointer-events-none absolute mr-auto h-[656px] w-[170px]"
            id="dream-collections-building"
          />

          <div className="pointer-events-none left-20 top-80 z-0 w-[302px]">
            <Slide direction="up">
              <img src="/images/gifs/big-dreams-boy.gif" className="-z-10" />
            </Slide>
          </div>
        </div>
      </div>

      {/* Community section - desktop */}
      <div className="relative hidden min-h-screen w-full max-w-[830px] snap-center snap-always flex-row items-center gap-4 p-2 pt-20 md:flex">
        <div className="relative flex w-1/2 flex-col items-center justify-center gap-4 rounded-3xl px-4 py-10">
          <Slide
            duration={2000}
            cascade
            damping={0.1}
            className="pointer-events-none z-10 mr-auto w-full max-w-[313px]"
          >
            <img src="/landing-page/community-card.png" />
          </Slide>
          <Slide
            duration={2000}
            cascade
            damping={0.1}
            className="pointer-events-none absolute -left-40 z-10 w-full max-w-[365px]"
          >
            <img src="/images/gifs/community-girl-fly.gif" />
          </Slide>
        </div>
        <div className="flex  w-2/3 flex-col items-start gap-4 rounded-3xl px-4 py-10 md:w-1/2" id="community-text">
          <Slide direction="right" duration={2000} cascade damping={0.1}>
            <span className="text-left font-bricolage text-4xl font-medium leading-6 text-white shadow-[#775BD459]/35 drop-shadow-md">
              Community
            </span>
            <span className="text-left text-xl text-white">
              Spread the love. Direct message, comment, and hype up your crew with badges and stickers.
            </span>
            <div className="w-fit">
              <Button label="Join the Community" onClick={() => navigate("/login")} />
            </div>
          </Slide>
          <Slide
            duration={2000}
            direction="right"
            className="pointer-events-none absolute bottom-12 right-20 -z-10 w-full max-w-[240px]"
          >
            <img src="/landing-page/cloud.svg" />
          </Slide>
        </div>
      </div>

      {/* Community section - mobile */}
      <div className="relative mb-48 flex h-[100svh] max-h-[100svh] min-h-[100svh] w-full max-w-[830px] snap-center snap-always flex-row p-2 pt-20 md:hidden">
        <div
          className="relative z-0 mb-20 ml-auto flex w-2/3 flex-col items-center gap-4 self-center rounded-3xl bg-white p-4"
          id="dream-collections-text"
        >
          <div className="relative">
            <Slide
              duration={2000}
              direction="left"
              className="pointer-events-none absolute -left-32 top-12 -z-10 w-full max-w-[240px]"
            >
              <img src="/landing-page/cloud.svg" />
            </Slide>
            <Slide direction="right" duration={2000} className="-mb-10 -mt-16 w-full" cascade damping={0.1}>
              <img src="/landing-page/community-card.png" className="z-0" />
            </Slide>
            <Slide direction="right" duration={2500} className="absolute top-0 w-full">
              <img src="/landing-page/community-sticker-1.png" className="absolute -right-8 top-2 w-20" />
              <img src="/landing-page/community-sticker-2.png" className="absolute -left-8 top-2 w-20" />
            </Slide>
          </div>
          <Slide cascade damping={0.1} duration={2000} direction="right" className="flex flex-col items-center">
            <span className="mt-6 text-center font-bricolage text-[24px] font-bold leading-8 text-[#775BD4]">
              Community
            </span>
            <span className="text-center text-sm text-black">
              Spread the love. Direct message, comment, and hype up your crew with badges and stickers.
            </span>
            <Button className="w-auto" label="Join the Community" onClick={() => navigate("/login")} />
          </Slide>
        </div>
        <div className="absolute -left-8 bottom-16 flex w-1/3 flex-col items-center justify-center gap-4 self-center">
          <div className="pointer-events-none relative w-[377px]">
            <Slide direction="up">
              <img src="/images/gifs/community-girl-fly.gif" className="pointer-events-none z-10 my-auto w-[377px]" />
            </Slide>
          </div>
        </div>
      </div>

      {/* What's in our dreamvault section */}
      <div className="flex min-h-screen w-full flex-col gap-6 pt-20">
        <span className="text-center font-bricolage text-2xl font-medium leading-6 text-[#DDEFFF]">
          {`What's in our `} <b>DreamVault?</b>
        </span>
        <div
          className="z-10 flex h-[520px] w-full snap-center snap-always flex-col items-center justify-center gap-4 self-center bg-cover py-16 sm:bg-contain"
          style={{
            backgroundImage: "url(/images/clouds/pattern.svg)",
            backgroundRepeat: "repeat-x",
            backgroundOrigin: "border-box",
            backgroundSize: "800px 520px",
          }}
        >
          <span className="text-center leading-5 text-[#775BD4]">
            We have <b>millions</b> of premium and luxury products you can unlock in our
            <b> DreamVault.</b>
            <br />
            Checkout with <b>$MOTHER</b> for a 10% discount!
          </span>
          <div className="flex w-screen gap-4 overflow-x-auto p-2 md:items-center md:justify-center">
            <div className="relative flex aspect-square w-[174px] min-w-[174px] flex-col gap-3 rounded-3xl bg-white shadow-md shadow-[#775BD459]/35">
              <img
                className="h-full w-full object-cover object-center rounded-3xl"
                src="/landing-page/products/image1.png"
              />
            </div>
            <div className="relative flex aspect-square w-[174px] min-w-[174px] flex-col gap-3 rounded-3xl bg-white shadow-md shadow-[#775BD459]/35">
              <img
                alt=""
                className="h-full w-full object-cover object-center rounded-3xl"
                src="/landing-page/products/image2.png"
              />
            </div>
            <div className="relative flex aspect-square w-[174px] min-w-[174px] flex-col gap-3 rounded-3xl bg-white shadow-md shadow-[#775BD459]/35">
              <img
                alt=""
                className="h-full w-full object-cover object-center rounded-3xl"
                src="/landing-page/products/image3.png"
              />
            </div>
            <div className="relative flex aspect-square w-[174px] min-w-[174px] flex-col gap-3 rounded-3xl bg-white shadow-md shadow-[#775BD459]/35">
              <img
                alt=""
                className="h-full w-full object-cover object-center rounded-3xl"
                src="/landing-page/products/image4.png"
              />
            </div>
            <div className="relative flex aspect-square w-[174px] min-w-[174px] flex-col gap-3 rounded-3xl bg-white shadow-md shadow-[#775BD459]/35">
              <img
                alt=""
                className="h-full w-full object-cover object-center rounded-3xl"
                src="/landing-page/products/image5.png"
              />
            </div>
            <div className="relative flex aspect-square w-[174px] min-w-[174px] flex-col gap-3 rounded-3xl bg-white shadow-md shadow-[#775BD459]/35">
              <img
                alt=""
                className="h-full w-full object-cover object-center rounded-3xl"
                src="/landing-page/products/image6.png"
              />
            </div>
            <div className="relative flex aspect-square w-[174px] min-w-[174px] flex-col gap-3 rounded-3xl bg-white shadow-md shadow-[#775BD459]/35">
              <img
                alt=""
                className="h-full w-full object-cover object-center rounded-3xl"
                src="/landing-page/products/image7.png"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="relative flex min-h-screen w-full max-w-[830px] snap-center snap-always flex-row justify-center">
        <img className="pointer-events-none absolute w-full max-w-[496px] self-center" src="/images/clouds/full.svg" />
        <div className="z-10 m-16 flex flex-col items-center justify-center gap-6 self-center">
          <span className="w-[326px] text-center font-bricolage text-2xl leading-6 text-[#3D2788]">
            Empower your Creativity. Build your <b>Community.</b>
          </span>
          <div className="w-fit">
            <Button label="Join now and unlock your dreams!" onClick={() => navigate("/login")} />
          </div>
        </div>
      </div>

      <div className="relative flex w-full snap-center snap-always flex-col items-center gap-6 bg-[#EBF5FF] px-1 py-14 md:px-4">
        <div className="hidden grid-cols-2 md:grid md:grid-cols-1 text-center">
          <Link to="/signup" className="text-[#4D30AA] underline text-md">
            Sign up
          </Link>
          <Link to="/login" className="text-[#4D30AA] underline text-md">
            Sign in
          </Link>
          <Link to="/faq" className="text-[#4D30AA] underline text-md">
            FAQ
          </Link>
          <Link to="/legal/terms" className="text-[#4D30AA] underline text-md">
            Terms and Conditions
          </Link>
          <Link to="/legal/privacy" className="text-[#4D30AA] underline text-md">
            Privacy
          </Link>
        </div>
        <div className="flex w-min flex-row rounded-full bg-white px-4 py-2">
          <a
            href="https://www.instagram.com/dreamvault?igsh=aTdkcG9sYmhxYWxw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="block h-5 w-5 text-[#4D30AA]">
              <svg fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </a>
        </div>
        <div className="flex justify-center items-center text-primary">
          <Logo />
        </div>
        <span className="text-center text-[#4D677F]">
          © {new Date().getFullYear()} DreamVault, LLC. All rights reserved.
        </span>
        <div className="absolute bottom-20 -z-10 h-[430vh] w-[99vh]">
          <img src="/landing-page/background.png" className="pointer-events-none -z-10 opacity-70 mix-blend-multiply" />
        </div>
      </div>
      <div ref={pageBottomRef} />
    </div>
  );
}

function mixColors(color1: string, color2: string, percent: number) {
  // Convert hex to RGB values (assuming format #RRGGBB)
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  // Calculate difference for each color component
  const deltaRed = rgb2!.r - rgb1!.r;
  const deltaGreen = rgb2!.g - rgb1!.g;
  const deltaBlue = rgb2!.b - rgb1!.b;

  // Calculate RGB at the percentage
  const red = Math.round(rgb1!.r + deltaRed * percent);
  const green = Math.round(rgb1!.g + deltaGreen * percent);
  const blue = Math.round(rgb1!.b + deltaBlue * percent);

  // Clamp values to 0-255
  const clampedRed = Math.max(Math.min(red, 255), 0);
  const clampedGreen = Math.max(Math.min(green, 255), 0);
  const clampedBlue = Math.max(Math.min(blue, 255), 0);

  // Convert RGB back to hex
  return rgbToHex(clampedRed, clampedGreen, clampedBlue);
}

// Helper function to convert hex to RGB
function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

// Helper function to convert RGB back to hex
function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase();
}
