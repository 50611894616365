import { useEffect } from "react";

import { useChord } from "@/hooks/useChord";
import { usePageContext } from "vike-react/usePageContext";

export default function LayoutProtected({ children }: { children: React.ReactNode }) {
  const chord = useChord();

  const pageContext = usePageContext();
  const { urlPathname } = pageContext;

  useEffect(() => {
    chord.page();
  }, [chord, urlPathname]);

  return children;
}
